import styled from "styled-components";
import LogoImg from '../../../assets/logo.png';
import LogoAlone from '../../../assets/logo_alone.png';
import { device } from "../../styles/breakpoints";


interface LogoProps{
    alone?:boolean;
}
export const Logo = styled.div<LogoProps>`
    background-image: url(${LogoAlone});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 0rem;
    height: 100%;
    min-width: 200px;

    @media ${device.tablet}{
        background-image: url(${LogoAlone});
        margin-left: 1rem;
    }

`