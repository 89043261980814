import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { Button } from "../atoms/Button";
import { OnConnected } from "../molecules/OnConnected";
import WalletConnectProvider from "@walletconnect/web3-provider";


export const ConnectButtonStyled = styled.div`
    width: 100%;

`

const ButtonClass = styled.div`
    font-family: 'ShortStackRegular', Helvetica, sans-serif;
    text-transform: uppercase;
`

// Web3 connection/configuration
const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: "INFURA_ID" // required
        }
    }
    /* See Provider Options Section */
};

const web3Modal = typeof window === "undefined" ? undefined : new Web3Modal({
    network: "any", // optional
    cacheProvider: true, // optional
    providerOptions, // required
});

type TNetwork = 'mainnet' | 'network'

export const ConnectButton = () => {
    const [provider, setProvider] = useState<ethers.providers.Web3Provider>()
    const [address, setAddress] = useState<string>()
    const [network, setNetwork] = useState<string>();


    const connectToWeb3 = async () => {
        try {
            if (typeof window === "undefined") return;

            const instance = await web3Modal?.connect();
            const provider = new ethers.providers.Web3Provider(instance,'any');
            const address = await provider.getSigner().getAddress();
            const network = await provider.getNetwork();
            setAddress(address);
            setProvider(provider);
            setNetwork(network.name);

            console.log("Network", network)


            instance.on('accountsChanged', async (accounts: any) => {
                console.log("Accounts", accounts);
                if (accounts.length == 0) {
                    web3Modal?.clearCachedProvider();
                    setProvider(undefined);
                }
                setAddress(accounts[0])
            })
            instance.on('networkChanged', async (e: any) => {
                const network = await provider.getNetwork()
                setNetwork(network.name);
            })

            instance.on('error',(e:any)=>{
                console.log("Erroooor",e)
            })

            provider.on('error',(e:any)=>{
                console.log("Erroooor",e)
            })


            instance.on('disconnect', async () => {
                console.log("Disconnected from web3");
                setProvider(undefined);

            })

            return () => {
                instance.off('accountsChanged');
                instance.off('disconnect')
            }
        } catch (e) {
            console.log("Error on ConnectButton", e);
        }

    }


    useEffect(() => {
        const connect = async () => {
            try {
                if (typeof window === "undefined") return;
                if (web3Modal?.cachedProvider) {
                    // caches if the 'user' has already connect
                    await connectToWeb3()
                }
            } catch (e) {
                console.log("Error on useEffect", e);
            }

        }

        connect();
    }, [])

    return <ConnectButtonStyled>
        {provider ?
            <OnConnected provider={provider} address={address} network={network} />
            : <Button onClick={async () => {
                await connectToWeb3()
            }}><ButtonClass>Connect Wallet</ButtonClass></Button>}
    </ConnectButtonStyled>
}