import * as React from 'react';
import styled from 'styled-components';
import { FlexCenter } from '../atoms/Center';
import HeroSvg from '../../../assets/hero_ginfts.svg'
import { device } from '../../styles/breakpoints';
import '../../css/typography.css';
import { ConnectButton } from './ConnectButton';
import { motion } from 'framer-motion';
import { fadeIn } from '../atoms/variants';
import { fontSize } from '../../css/sizeTypographies';

const HeroStyled = styled.div` 
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   position: relative;
   border-bottom: 5px solid black;
   text-align: center;
   
`
const Header = styled.span`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    font-size: ${fontSize.mainHeading};
    font-weight: 900;
    margin-top:5px;
    padding: 1.2em 0 .5em 0;

    @media ${device.laptop} {
        margin-top:60px;
        padding: 1em 0;
    }
`
const HeroImg = styled.div`
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center top;
    background-image: url(${HeroSvg});
    pointer-events: none;
    margin-top: 2%; 

    @media ${device.mobileS} {}
    @media ${device.mobileM} {}
    @media ${device.mobileL} {}
    
    @media ${device.tablet} {
        height: 600px;
        margin-top: -10%;
    }
`


export const Hero = () => {
    return <HeroStyled>
        
            <Header>
                NFT 4 YOUR <br/> FRENS & FAM
            </Header>
        <FlexCenter>
            <ConnectButton />
        </FlexCenter>
            
            <HeroImg/>
    </HeroStyled>
}