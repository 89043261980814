import React from 'react'
import styled from 'styled-components';
import bandaidComputer from '../../../assets/bandaidComputer.svg'
import { fontSize } from '../../css/sizeTypographies';
import { device } from '../../styles/breakpoints';
import { Colors } from "../../styles/colors"; 
import { BadGiftSpan } from '../atoms/BadGiftSpan';

const DivGlobal = styled.div`
    display: grid;
    padding: 20px 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 100px;
    max-width:100%;
    height: 100%;
    border: 5px solid black;
    border-radius: .5rem;
    position: relative;
    background-color: ${Colors.yellow};
    margin: 1rem;

    h3 {
        font-family: 'RustonBasicBlack', Helvetica, sans-serif;
        margin: 0;
        margin-bottom: 20px;
        font-size: ${fontSize.titleMobile};
        
        @media ${device.laptop} {
            margin-bottom: 3rem;
            font-size: ${fontSize.title};
        }
    }

    p {
        font-family: 'GraphikBold', Helvetica, sans-serif;
        font-size: ${fontSize.textMobile};
        margin:0.5rem 0 0rem 0;
    }

    .text-p {
        font-family: 'GraphikRegular', Helvetica, sans-serif;
        font-weight: 0;
        font-size:${fontSize.text};
        margin: 0.5rem 0 1.5rem 0;
    }

    .text {
        grid-row: 1 / 2;
    }

    .p-mb-reseting {
        margin-bottom: 0;
    }


  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 50px;
    padding:100px;
    min-height: 100%;
    padding-bottom: 0;

    .text {
        grid-column: 2/3;
    }
        
    h3 {
        font-size: ${fontSize.title};
    }
  }  
`
const BandaidComputerImg = styled.div`
    width: 100%;
    min-height:200px;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: left;
    background-image: url(${bandaidComputer});
    

    @media ${device.laptop} {
        background-position: center;
        margin-bottom: 5rem;
    }
`

const FAQ = () => {
    return (
        <DivGlobal>
            <BandaidComputerImg />
            <div className='text'>
                <h3>FAQ</h3>
                <p>What is an NFT? What Is An N..FT?</p>
                <p className='text-p'>Oh, hmm let me google that for you..</p>
                <p>How much is a <BadGiftSpan/> and how many are there?</p>
                <p className='text-p'>Did you scroll directly to the FAQ? Are we answering a question with a question? There are 6,969 <BadGiftSpan/> We are holding 69 random gifts for giveaways, promos and as last minute presents for our small, nimble crew.</p>                
                <p>Where does my <BadGiftSpan/> go after purchase?</p>
                <p className='text-p'>Just like the family AAA card that your mom makes sure you carry. Your wallet. It goes in your wallet or the wallet you sent it to.</p>                
                <p>Since I bought a <BadGiftSpan/> do I own the rights to the design?</p>
                <p className='text-p'>Hell yeah! I mean, probably. Hit us up on Twitter</p>
                <p>I love <BadGiftSpan/>. How do I get involved?</p>
                <p className='p-mb-reseting text-p'>Oh nice you really are new to this, huh? Take your bad self to our Twitter. We are working day and night to make bad gifts work hard for their money and would love your bad ideas.</p>
            </div>
        </DivGlobal>
    )
}

export default FAQ