import styled from "styled-components"
import React from 'react';
import EthIcon from '../../images/ethereumIcon.svg'

interface EthProps {
    price: number;
    size:string;
}

const StyledEth = styled.div<EthProps>`
    display: inline-block;
    .ethIcon{
        display: inline-block;
        background-image: url(${EthIcon});
        min-width: ${p=>p.size};
        min-height: ${p=>p.size};
        background-size: contain;
    }
`

export const Eth = (props: EthProps) => {

    return <StyledEth {...props}>{props.price}
        <div className="ethIcon">
        </div>
    </StyledEth>
}