import { PageProps } from "gatsby";
import * as React from "react"
import { ISection, Section } from "../components/templates/Section";
import styled from 'styled-components'
import { GlobalStyle } from "../styles/globalStyle";
import { useEffect } from "react";
import { Hero } from "../components/organisms/Hero";
import WhatsInTheBox from "../components/organisms/WhatsInTheBox";
import FAQ from "../components/organisms/FAQ";
import { Navbar } from "../components/templates/Navbar";
import { Backstory } from "../components/organisms/Backstory";
import { Colors } from "../styles/colors";
import { Roadmap } from "../components/organisms/Roadmap";
import {Helmet} from "react-helmet";

import Footer from "../components/organisms/Footer";
import SecretPage from "../components/organisms/SecretPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IndexContainer = styled.div`
  margin:0px;
  padding: 0px;
  overflow-x: hidden;
`
const FAQContainer = styled.div` //For overlaping FAQ and Footer
  position:relative;
  margin:0px;
  padding: 0px;
`

const IndexPage = (props: PageProps) => {
  const sections: ISection[] = [
    { color: '#3D7CDA', name: 'home', component: <Hero /> },
    { color: Colors.yellow, name: 'backstory', component: <Backstory /> },
    { color: Colors.yellow, name: 'whats-in-the-box', component: <WhatsInTheBox/> },
    { color: Colors.red, name: 'roadmap', component: <Roadmap /> },
    { color: Colors.green, name: 'FAQ',component:<FAQContainer><FAQ/> </FAQContainer>},
    { color: Colors.pink, name: 'footer',component:<Footer/> },
    { color:Colors.bone,name: 'beyond',component:<SecretPage /> }

  ]
  const actualSection = props.params['*'];

  useEffect(() => {
    if (actualSection) {
      //scroll to that section
      const element = document.getElementById(actualSection);
      element?.scrollIntoView();
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BadGifts - CoolFunStuff</title>
        <link rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto"/>
      </Helmet>
      <GlobalStyle />
      <IndexContainer key='indexContainer' >
        <Navbar />
        {sections.map((e, index) => {
          return <Section key={e.name} sections={sections} index={index} />
        })}
      </IndexContainer>
      <ToastContainer position='bottom-right'/>
    </>)
}

export default IndexPage;