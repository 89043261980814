
import styled from "styled-components";
import { device } from "../../styles/breakpoints"
import { Colors } from "../../styles/colors"

export const LinksDiv = styled.div`
font-family: 'RustonBasicBlack', Helvetica, sans-serif;
font-size: 1.2rem;
display: flex;
flex-direction: row;
justify-content: center;
margin-bottom: 2.5rem;
gap: 1.5rem;
width:  100%;

@media ${device.laptop} {
width:  100vw;
gap: 8rem;
}
a {cursor: pointer; text-decoration: none; color: #000; }
a:focus,  a:visited { color: inherit;text-decoration: none; color:#000; }
a:hover, a:active  { color: inherit;text-decoration: none; color:${Colors.bone}; }
`
