import styled from "styled-components"
import React, {useEffect, useState} from "react"
import { ISecretItem, SecretPageItem} from "../molecules/SecretPageItem"
import { device } from "../../styles/breakpoints"
import SecretImg0 from '../../../assets/roadmap_last.png'
import SecretImg1 from '../../../assets/backstory_diamond.png'
import SecretImg2 from '../../../assets/secondary_3.png'
import SecretImg3 from '../../../assets/roadmap_0.png'
import SecretImg4 from '../../../assets/roadmap_75.png'
import SecretImg5 from '../../../assets/roadmap_25.png'
import { fontSize } from "../../css/sizeTypographies"

const SecretStyled = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: top;
   position: relative;
   text-align: left;
   padding: 2rem;

 
    @media ${device.laptop} { 
        margin-top: 0;
    }

`
const TitleDiv = styled.div`
    color: black;
    padding: 1em 0;
    
    p {
        margin:0;
        font-size: 1.2rem;
    }
    
    .paragraphs-headings {
        padding: 1rem 0 2rem 0;}
        font-size: 1.2rem;

    @media ${device.laptop} { 
        .paragraphs-headings {
        padding: 1rem 0 2rem 0;
        }
    }
    

`
const SecretHeading = styled.span`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 38px;

    @media ${device.laptop} { 
        font-size: ${fontSize.title};
    }

`
const SecretItemsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 100px;
    
    @media ${device.laptop} { 
        grid-template-columns: repeat(3, 1fr);
        margin: 0 0 1rem 0;
    }

`

const SecretItems: ISecretItem[] = [
    {
        image: SecretImg0,
        title: 'Secondary Sales Incentives',
        text: 'After you’re all stuck with these Bad Gifts we’re gonna find ways to stay with you forever and ever and ever. We have your address, y’know.'
    },
    {
        image: SecretImg1,
        title: 'Merch and Homeware',
        text: 'We get it. You’re bad to the bone and gotta show it. '
    },
    {
        image: SecretImg2,
        title: 'BAD GIFTs IRL for your shelf (or dashboard in ur El Camino)',
        text: 'Submit yours for a chance to hold a physical version in your soft, fleshy hands. '
    },
    {
        image: SecretImg3,
        title: 'Events and Meetups',
        text: 'We love rolling up to bars from classy to trashy, leaning back and vaping the night away. Come join us.'
    },
    {
        image: SecretImg4,
        title: 'Animated shorts showing off the bad gifts in a highly bingeable steamable way',
        text: 'Someone on the team designed all the characters on FX’s Archer and we won’t untie him until he makes you some little movies. ',
    },
    {
        image: SecretImg5,
        title: '2022 Holidays with your plus one - Holiday Parties and Pop Up BAD GIFT Shops',
        text: 'We imagine your SO will have their ish together by the end of 2022 but, if not, any event will include space for a holder’s plus one. Choose wisely. ',
    }   
]

const SecretPage = () => {

    return (
        <SecretStyled>
            <TitleDiv>
                <SecretHeading>Beyond #GiftGang</SecretHeading>
                <div className="paragraphs-headings">
                    <p>We are about more than cookie cutter giveaways and contests.</p>
                    <p>We’re bad for life and prioritize accordingly.</p>
                </div>
            </TitleDiv>
            <SecretItemsContainer>
                {SecretItems.map((val,index) => {
                    return <SecretPageItem items={SecretItems} index={index} me={val} key={index} />
                })}
            </SecretItemsContainer>
        </SecretStyled>
    )
}

export default SecretPage
