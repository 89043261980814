import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export interface ICounterProps {
  from: number;
  to: number;
  inView: boolean;
}

const Percentage = styled.div`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    max-width: 70px;
    font-size: 2.5em;
    font-weight: bolder;
    text-align: left;
    margin: 1rem 0;
    
    @media ${device.mobileM} {
        font-size: 3em;
    }
    @media ${device.laptop} {
        font-size: 4em;
    }
`

export default function Counter(props: ICounterProps) {
  const nodeRef: any = useRef();


  useEffect(() => {
    if(props.inView) {
      const node = nodeRef.current;
      const controls = animate(props.from, props.to, {
        duration: .7,
        onUpdate(value) {
          node.textContent = value.toFixed() + '%';
        }
      });
      return () => controls.stop();
    }

  }, [props.from, props.to, props.inView]);

  return <Percentage ref={nodeRef}></Percentage>;
}