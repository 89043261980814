import styled from "styled-components"
import React from 'react';
import Blockies from "react-blockies";
import { Flex } from "./Flex";

interface BlockieProps {
    seed: string
    onClick?:(seed:string)=>void
    size?:number;
}
const BlockieHolder = styled.div<{size:string,click:boolean}>`
    border:2px solid black;
    display: inline-block;
    border-radius: .5rem;
    overflow: hidden;
    width: ${p=>p.size};
    height: ${p=>p.size};
    cursor: ${p=>p.click?'pointer':'auto'};
    
`
export const Blockie = ({ seed,onClick,...rest }: BlockieProps) => {
    const size = 5;
    const scale = rest.size?rest.size:5;
    const sizeInPx = `${size*scale}px`;
    return (
            <BlockieHolder click={onClick!=undefined} size={sizeInPx} onClick={()=>{
                if(!onClick)return;
                onClick(seed);
            }}>
                <Flex extras={`
                    justify-content:center;
                    align-items:center;
                `}>
                   <Blockies seed={seed} size={size} scale={scale}/> 
                </Flex>
                
            </BlockieHolder>
    )
}