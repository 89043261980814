import React from 'react'
import styled from 'styled-components';
import CandleBoxSvg from '../../../assets/candle_box1.svg'
import { device } from '../../styles/breakpoints'; 
import '../../css/typography.css';
import { BadGiftSpan } from '../atoms/BadGiftSpan';
import { fontSize } from '../../css/sizeTypographies';

const DivGlobal = styled.div`
    display: grid;
    padding: 0 25px 25px 25px;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 100px;
    max-width:100%;
    min-height: 100vh;

    h3 {
        font-family: 'RustonBasicBlack', Helvetica, sans-serif;
        margin: 0;
        font-size: ${fontSize.titleMobile};
        text-transform: uppercase;
    }

    p {
        font-size: 1.2em;
        margin-top:2rem;
        margin-bottom: 0;
    }   

  @media ${device.laptop} { 
    grid-template-columns: repeat(2, 1fr);
    margin: 0 50px 0 50px;
    padding-top: 0px;
    min-height: 100%;
    padding-bottom: 150px;
        
    h3 {
        font-size: 50px;

        @media ${device.laptop} {
            margin-bottom: 3rem;
        }
    }

    
  }  
`
const CandleBoxImg = styled.div`
    width: 100%;
    min-height:200px;
    margin-top: 25px;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
    background-image: url(${CandleBoxSvg});
`

const WhatsInTheBox = () => {
    return (
        <DivGlobal>
            <div className='reseting-div'>
                <h3>What's in the box?</h3>
                <p><BadGiftSpan /> is your NFT gift shop complete with a digital sharpie for adding a personal note to each gift.
                </p>
                <p>The Bad Gift shop has everything! That padded coat hanger set from your Aunt Marilyn is there... next to the eggnog-scented candle you got stuck with from the office White Elephant.
                </p>
                <p>So, skip the last minute drugstore gifts and get something that can’t (and won’t) be tossed in the trash the moment you leave.</p>
                <p>Or, get yourself something you never knew you didn’t want.</p>
            </div>
                <CandleBoxImg />        
        </DivGlobal>
    )
}

export default WhatsInTheBox