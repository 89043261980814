import { BigNumber, ethers } from "ethers"
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Colors } from "../../styles/colors";
import { Blockie } from "../atoms/Blockie";
import { Flex } from "../atoms/Flex";
import BadGiftsABI from '../../ABI/BadGifts.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "../atoms/Input";
import { useContractAddress } from "../../hooks/useContractAddress";
import { motion } from 'framer-motion';
import { fadeIn } from '../atoms/variants';
import { _Network } from "../../utils/constants";
import { Network } from "../atoms/Network";
import { Eth } from "../atoms/Eth";
import { isMinted } from "../../utils/isMinted";
import { sleep } from "../../utils/sleep";

interface OnConnectedProps {
    provider: ethers.providers.Web3Provider
    address?: string,
    network?: string
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
`

const Content = styled.div<{ bgColor: string }>`
    display: flex;
    flex-direction: row;
    gap:.3em;
    align-items: center;
    font-size: large;
    background-color: ${p => p.bgColor};
    padding: 1rem;
    border-radius: .5em;
    border: 3px solid black;
    box-shadow: 10px 10px 0px black;
`
const MintTitle = styled.span`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
`
const MintButton = styled.button`
    border:2px solid black;
    background-color: ${Colors.pink};
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    padding: 1rem;
    font-size: large;
    border-radius: .5rem;
    height: 4rem;
    cursor: pointer;
    transition: padding .3s, margin-top .3s,background-color .3s;
    :hover{
        background-color: #e388c3;
    }
    :active{
        background-color: #d47cb6;
        padding: .9rem;
    }
`
const Note = styled.div`
    border: 2px solid #0d501e;
    background-color: #97e9bc9e;
    padding: .3rem;
    font-size: smaller;
    color:#0a4123e3;
    border-radius: .5rem;
    margin: .1rem;
    margin-bottom: .5rem;
    box-shadow: 5px 5px 0 #0d501e;
    
`
const NoMessageNote = styled.div`
    text-align: center;
    font-size: .8rem;
    color: #464343;
`

export const OnConnected = ({ provider, address, network }: OnConnectedProps) => {
    const [toAddress, setToAddress] = useState<string>('');
    const [message, setMessage] = useState<string>();
    const [amount, setAmount] = useState<number>(1);

    const contractAdr = useContractAddress();

    const mint = async (to: string, amount: number, message: string) => {
        try {
            if (!contractAdr) return;
            const contract = new ethers.Contract(contractAdr, BadGiftsABI, provider.getSigner())

            const mintTxt = await contract.functions.mintWithMessage(to, amount, message, { value: ethers.utils.parseEther(`${0.02 * amount}`) });
            console.log("mintTxt", mintTxt);
            toast.info(<div>Thank you, please wait until your transaction is done... 
                <a target={'_blank'} href={_Network == 'rinkeby' ? `https://rinkeby.etherscan.io/tx/${mintTxt.hash}` : `https://etherscan.io/tx/${mintTxt.hash}`}>
                    [see on etherscan]
                </a>
            </div>)
            let finished = false;
            while (!finished) {
                await sleep(1000);

                let finishedTx = await isMinted(mintTxt.hash, provider);
                console.log("Waiting", finishedTx)
                if (finishedTx) {
                    toast.success(<div>All done
                        <a target={'_blank'} href={_Network == 'rinkeby' ? `https://rinkeby.etherscan.io/tx/${finishedTx.transactionHash}` : `https://etherscan.io/tx/${finishedTx.transactionHash}`}>
                            [see on etherscan]
                        </a>
                    </div>)
                    finished = true;
                }

            }
        } catch (e: any) {
            console.error("Error on mint", { e });
            toast.error('Mint Error: ' + (e.reason ? e.reason : e.message))
        }

    }
    return <Container as={motion.div} variants={fadeIn("down")} initial="initial" animate="animate">
        <Content bgColor={Colors.bone}>
            Connected as {address && <Blockie seed={address} />} {address?.substring(0, 5)}...
            <Network network={network} />
        </Content>
        <Content bgColor={Colors.yellow}>
            <Flex extras={`
            flex-direction:column;
            gap:.5rem
            `}>
                <MintTitle>Mint Some Bad Gifts!</MintTitle>
                <Flex extras={`
                    gap:.5rem;
                    align-items:center;
                `}>
                    <Input value={toAddress} label={`Recipient's Address`} placeholder="Address" onChange={(e: any) => {
                        setToAddress(e.target.value);
                    }} />

                    <Blockie size={10} seed={toAddress ? toAddress : address ? address : ''} onClick={(seed) => {
                        setToAddress(seed);
                    }}></Blockie>


                </Flex>
                <Input placeholder="Your message" label="Include a Note" value={message} onChange={(e: any) => {
                    if (e.target.value.length >= 25) return;

                    setMessage(e.target.value);
                }} />

                <NoMessageNote>Type a period/dot "." to send without note</NoMessageNote>

                {message && message.length > 0 && <Note>Max 24 characters. {24 - (message.length)} left.</Note>}
                <Input type="number" value={'' + amount} label="Amount of GINFTS (Max 10)" onChange={(e: any) => {
                    const value = parseInt(e.target.value);
                    if (isNaN(value) || value < 1) return setAmount(1);

                    setAmount(value);
                }} />
                {amount > 1 && <Note>Multiple mints will include the same message.</Note>}
                <MintButton onClick={async () => {

                    if (network != _Network) {
                        return toast.error(`Please change your wallet to ${_Network}`)
                    }

                    if (toAddress && message && message.length < 25) {
                        await mint(toAddress, amount, message);
                    } else {
                        toast.error("Please fill all the inputs");
                    }

                }}>Mint! <Eth price={0.02 * amount} size={'20px'} /></MintButton>
            </Flex>
        </Content>
    </Container>
}