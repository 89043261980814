import React from "react"
import styled from "styled-components"

export interface HamburgerItemProps {
    isOpen: boolean; 
}

export const HamburgerStyled = styled.div<{ isOpen: boolean }>`
                   
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    
    

    .burger{
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: black;
        transform-origin: 1px;
        transition: all 0.3s linear;
    }

    .burger1{
        transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }
    .burger2{
        transform: ${ props => props.isOpen ? 'translateX(100%)' : 'translateX(0)'};
        opacity: ${ props => props.isOpen ? 0 : 1};
    }
    .burger3{
        transform: ${ props => props.isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
`

export default function Hamburger(props: HamburgerItemProps){
    return (
        <>
            <HamburgerStyled isOpen={props.isOpen}>
                <div className="burger burger1" />
                <div className="burger burger2" />
                <div className="burger burger3" />
            </HamburgerStyled>
        </>
    )
}