import React, { ReactNode } from "react"
import styled from 'styled-components';
import { navigate } from 'gatsby'
import { DividedBackground } from "../atoms/DividedBackground";


export interface ISection {
    name: string,
    color?: string
    component: ReactNode
}
export interface SectionProps {
    index: number,
    sections: ISection[]
}


const Container = styled.div`
background-color: ${props => {
        return props.color
    }};
    display: flex;
`

/**
 * This is the main container of the BadGifts page, 
 * it controls how a section is loaded when the user scrolls down.
 * @param props SectionProps
 */
export const Section = (props: SectionProps) => {
    const me = props.sections[props.index];
    const top = props.sections[props.index - 1];
    const bottom = props.sections[props.index + 1];
    return <Container id={me.name} color={me.color} onMouseOver={() => {
        typeof window !== 'undefined' ? window.location.href : '';
        const navigateName = `/${me.name}`

        if(navigateName != window.location.pathname){
            navigate(navigateName)
        }
        
    }}>
        {me.component}
        {!me.color && <DividedBackground topSize={2} colors={{ top: top.color, bottom: bottom.color }} />}
    </Container>
}
