import styled from "styled-components"
import React, { useState } from 'react';
import { Colors } from "../../styles/colors";

interface InputProps{
    onChange?:(e:any)=>void,
    label:string,
    value?:string,
    placeholder?:string
    type?:string;
}

const StyledInput = styled.input`
  border:2px solid black;  
  padding:1rem;
  font-size: large;
  width: 100%;
  border-radius: 0.5rem;
  margin-top:2rem;
  & :focus{
        outline: 0px;
    }
`
const Label = styled.div<{active:boolean}>`
    position: absolute;
    font-size: .8rem;
    top: .2rem;
    background: linear-gradient(${Colors.pink}, white);
    color:#59001e;
    padding:.5rem;
    border:2px solid black;
    border-bottom: 0px;
    border-radius: 0.5rem 0.5rem 0rem 0.0rem;
    left:1rem;
    transition: top .1s;
    user-select: none;
`

const InputContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
`

export const Input = (props: InputProps) => {
    const [active,setActive]=useState(false);

    return <InputContainer>
        <Label active={active}>{props.label}</Label>
        <StyledInput {...props} onFocus={(e)=>{
            setActive(true);
        }}
        onBlur={()=>{
            setActive(false);
        }}
        ></StyledInput>
    </InputContainer>
}