import React from "react"
import styled from "styled-components"
import { Colors } from "../../styles/colors"
import YellowGiftImg from '../../../assets/yellow_gift.png'
import FlowersBouquetImg from '../../../assets/flowersBouquet.png'
import DiamondImg from '../../../assets/backstory_diamond.png'
import { Button } from "../atoms/Button"
import { device } from "../../styles/breakpoints"
import '../../css/typography.css';
import { badGiftsURLs } from "../atoms/Utils"
import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer"
import { useEffect } from 'react';
import { useMediaQuery } from "react-responsive"

const BackstoryInfo = styled.div`
    background-color: ${Colors.green};
    border: 5px solid black;
    border-radius: 3px;
    display: grid;
    padding: 1em 1em 4em 1em;
    margin-top: -100px;
    width: 80%;
    grid-template-columns: .4fr 1fr;
    grid-template-rows: .3fr 1fr;
    grid-template-areas: 
    "gift title"
    "text text"
    "space space";
    
    @media ${device.tablet} {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: .5fr 1fr;
        padding: 0rem 3rem 3rem 3rem;
        column-gap: 3rem;
       
    }

    
    @media ${device.laptop} {
        padding: 2rem 3rem 8rem 3rem;
        grid-template-areas: 
        "gift title"
        "gift text"
    }
      
    
`
const YellowGift = styled.div`
    width: 100%;
    height:100%;
    
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${YellowGiftImg});
    grid-area: 'gift';
    z-index: 1001;

    @media ${device.tablet} {
        margin-top: 1rem;
        height: 100%;
    }

    @media ${device.laptop} {
        padding-top: 4rem;
        margin-top: 0rem;
        height: 280%;
    }
`
const FlowersImg = styled.div`
    width: 100%;
    height:100%;
    
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${FlowersBouquetImg});
    grid-area: 'flowers';
    display: none;
    
    z-index: 1000;
    @media ${device.tablet} {
        margin-top: 1rem;
        height: 100%;
        display:none;
    }

    @media ${device.laptop} {
        display: block;
        padding-top: 0rem;
        margin-top: -7rem;
        height: 170%;
        align-items: left;
        opacity: 0;
    }
`

const BackstoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 100;
    padding-bottom: 2em;
    a:focus,  a:visited { color: inherit;text-decoration: none; color:#000; }
         
`
const InfoTextTitle = styled.div`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    grid-area: title;
    display: flex;
    align-items:center;
    padding: .6em;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: right;

    @media ${device.tablet} {
        align-items: flex-end;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
    }

    @media ${device.laptop} {
        font-size: 50px;
        padding: 0;
    }
    
`
const InfoTextContent = styled.div`
    font-family: 'GraphikRegular', Helvetica, sans-serif;
    grid-area: text;
    font-size: 1.2em;
    margin: 1rem 0 .5rem 0;

    @media ${device.tablet} {
        padding-top: 1rem;
    }
    @media ${device.laptop} {}

    
`
const ButtonHolder = styled.div`
    margin-top: -3em;
`
const DiamondImage = styled.div`
    height: 200px;
    width: 200px;
    background-size: contain;
    background-image:url(${DiamondImg});
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    @media ${device.laptop} {
        display: block;
        margin-right: 2.5rem;
        margin-bottom: 4rem;
    }
`
const Break = styled.div`
    padding-top: 1rem;
`

export const Backstory = () => {

    const {ref, inView} = useInView({
        threshold: 1
    });
    const animation = useAnimation();
    const animationFlowers = useAnimation();
    const isLapTop = useMediaQuery({ query: '(min-width: 1024px)' })    

    useEffect(() => {
        if (isLapTop) {
         
            if(inView) {
                animation.start({
                    opacity: 0,
                    transition: {
                        duration: 2
                    }
                })
                
                animationFlowers.start({
                    opacity: 1,
                    transition: {
                        duration: 2.5
                    }
                })
            } else {
                animation.start({
                    opacity: 1
                })

                animationFlowers.start({
                    opacity: 0,
                })
            }}
        
    }, [inView])

    return (
    <BackstoryContainer>
        <BackstoryInfo>

            <YellowGift 
                as={motion.div} 
                animate={animation}
            />
            <FlowersImg 
                 as={motion.div} 
                 animate={animationFlowers}
            />

            <InfoTextTitle>MINT 2 UR FRENS WALLET & ADD A NOTE</InfoTextTitle>
            <InfoTextContent ref={ref}>
                Gifting NFTs has never been more personal. With Bad Gifts NFTs you can mint a Bad Gift to a wallet of your choice (including your own) and write a personalized message to be printed on the final NFT. 
                <Break />
                The web2 you gifted, re-gifted and received bad presents IRL for decades before the crypto nerds and nerdettes gave us the greatest gift - the non-fungible token. Now the web3 you can set up your bestie’s wallet and send them a Bad Gift NFT. Include a personalized message that will echo on the blockchain for eternity!
            </InfoTextContent>
        </BackstoryInfo>
        <ButtonHolder>
            <a href={badGiftsURLs.twitter} target={"_blank"}>
                <Button >Tweet @ Us</Button>
            </a>
        </ButtonHolder>
        <DiamondImage/>

    </BackstoryContainer>
    )
}