import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html{
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'GraphikRegular', Helvetica, sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
  }
  .web3modal-modal-lightbox { z-index: 1000 !important; }
`;