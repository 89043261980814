import React from 'react'
import styled from 'styled-components'

interface DividerProps{
    size:number
    color:string|undefined
}
const Divider = styled.div<DividerProps>`
    background-color: ${props=>{return props.color}};
    width: 100%;
    flex-grow: ${props=>{return props.size}};
`
const Container = styled.div`
    background-color: bisque;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: -10;
`
const Line = styled.div`
    width: 100%;
    height: 3px;
    z-index: 10;
    background-color: black;
`

interface DividedBackgroundProps{
    topSize:number
    colors:{top:string|undefined,bottom:string|undefined}
}
export const DividedBackground = ({topSize,colors}:DividedBackgroundProps)=>{
 
    return (<Container>
        <Divider size={topSize} color={colors.top}/>
        <Line/>
        <Divider size={10} color={colors.bottom}/>
    </Container>)
}