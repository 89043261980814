import { Variants } from "framer-motion";

export const fadeIn = (direction: "up" | "down" = "up"): Variants => {
    return {
        initial: { 
            y: direction === "up"? 150 : -60, 
            opacity: 0, },
    
        animate: { 
            y: 0, opacity: 1, 
            transition: { duration: 1.5, ease: "easeInOut", },
        }, 
    }
}

export const staggerContainer: Variants = {
    initial: {},
    animate: {
        transition: {
            staggerChildren: 0.5,
            delayChildren: 1,
        },
    },
}