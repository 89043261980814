import { useEffect, useState } from "react"

export const useContractAddress =()=>{

    const [contractAdr,setAdddress] = useState<string>();

    useEffect(() => {
        async function call(){
            const req = await fetch('https://api.badgifts.wtf/contract')
            const data = await req.json();
            setAdddress(data.contract);
        }

        call();
    }, [])
    
    return contractAdr;
}