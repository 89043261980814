import { ReactNode } from "react"
import styled from "styled-components"
import { Colors } from "../../styles/colors"
import React from "react"

const ButtonSyled = styled.div`
    height:100px;
`

export const Button = (props: { children: ReactNode,onClick?:()=>void }) => {

    return <ButtonSyled onClick={props.onClick}>
        <MButton>
            {props.children}
        </MButton>
    </ButtonSyled>
}
export const MButton = styled.button`
    font-family: 'GraphikRegular', Helvetica, sans-serif;
    border-radius: 10em;
    padding: 1em;
    font-size: x-large;
    border-color: black;
    border-width: 3px;
    background-color: ${Colors.pink};
    box-shadow: -10px 10px 0 black;
    transition:background-color 0.2s, margin-top 0.2s,box-shadow 0.2s;
    
    cursor: pointer;

    &:hover {
        color: black;
        background-color: #e388c3;
    }

    &:active {
        margin-top: 10px;
        box-shadow: -5px 5px 0 black;
        
        background-color: #d47cb6;;
    }
`