import styled from "styled-components"
import { Colors } from "../../styles/colors"
import { _Network } from "../../utils/constants"
import React from "react"

const NetworkStyled = styled.div<{network?:string}>`
    background-color: ${p=>p.network=='homestead'?`#7af562`:Colors.yellow};
    border-radius: .5rem;
    font-size: .8rem;
    padding: .2rem;
    border:2px solid black;
    margin-top: -15px;
    box-shadow: 3px 3px 0px black;
    color: #000000c5;
    display: inline-block;
    user-select: none;
`

export const Network = ({network}:{network?:string})=>{

    return <NetworkStyled network={network}>
        {network=='homestead'?'mainnet':network}
    </NetworkStyled>
}