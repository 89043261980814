import React, {useEffect} from "react"
import styled from "styled-components"
import { device } from "../../styles/breakpoints"
import { Colors } from "../../styles/colors"
import { FlexCenter } from "../atoms/Center"
import { Flex } from "../atoms/Flex"
import { motion, useAnimation } from "framer-motion"
import Counter from "../atoms/Counter"
import { useInView } from 'react-intersection-observer';
import { fontSize } from "../../css/sizeTypographies"

export interface IRoadmapItem {
    percentage: number
    text: string
    color: string,
    image?: string;
}
export interface RoadmapItemProps {
    me: IRoadmapItem;
    index: number;
    items: IRoadmapItem[];
}

const RoadmapItemStyled = styled.div`
    padding: 1em;
    position: relative;
    z-index: 10;
`

const RoadmapItemInfo = styled.div<IRoadmapItem>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.color};
    border:5px solid black;
    border-radius: 4px;
    min-height: 10em;
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: .5em;
    z-index: 100;
    text-align: left;
    font-size: 1.2em;
`
const Percentage = styled.div`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    font-size: 2.5em;
    font-weight: bolder;
    text-align: left;
    margin: 1rem 0;
    
    @media ${device.mobileM} {
        font-size: 3em;
    }
    @media ${device.laptop} {
        font-size: 4em;
    }
`
const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: .2fr 1fr;
`
const Line = styled.div<{ isLong: boolean }>`
    position: absolute;
    height: ${props => props.isLong ? '100%' : '50%'};
    width: 5px;
    top: ${props => props.isLong ? 0 : '50%'};;
    left: 50%;
    background-color: black;
    z-index: -1;
    @media ${device.laptop} { 
        max-width: 800px;
        height: 5px;
        width: ${props => props.isLong ? '100%' : '50%'};;
        left: ${props => props.isLong ? 0 : '50%'};;
        top: 50%;
    }
`
const ImageContainer = styled.div`
    position: relative;
`
const Image = styled.div<{ url?: string }>`
    background-image: url(${props => props.url});
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    height: 170%;
    width: 150%;
    min-width: 200px;
    position: absolute;
    top:-90%;
    left:0;
    

    @media ${device.tablet} {
        top:-80%;
        height: 180%;
        left:0;
    }
    @media ${device.mobileL} {
        left:-65%;
    }
    @media ${device.laptop} {
        left:0;
    }
`

export const RoadmapItem = (props: RoadmapItemProps) => {

    const {ref, inView} = useInView()

    return <RoadmapItemStyled>
        <Line isLong={props.index != 0} />
        <RoadmapItemInfo ref={ref}  {...props.me} as={motion.div} whileHover={{ scale: 1.03}} >
       
            <TitleContainer >

                <Counter from={0} to={props.me.percentage} inView={inView}/>
                <ImageContainer>
                    <Image url={props.me.image} />
                </ImageContainer>
            </TitleContainer>
            {props.me.text}
        </RoadmapItemInfo>

    </RoadmapItemStyled>
}


const LastLine = styled.div`
    position: absolute;
    height: 50%;
    width: 5px;
    top:0;
    left: 50%;
    background-color: black;
    z-index: -1;
    
    @media ${device.laptop} { 
        max-width: 800px;
        height: 5px;
        width:100%;
        left: 0;
        top: 50%;
        
    }
`


const LastTitleContainer = styled(TitleContainer)`
grid-template-columns: 1fr .3fr;

@media ${device.laptop} {
    display:flex;
}
`
const LastImage = styled(Image)`
margin-left: -50px;

@media ${device.mobileS} {
        left:0;
        top:-30%;
        height: 150%;
    }
@media ${device.tablet} {
    left:60%;
    height: 350%;
}
@media ${device.laptop} {
    margin-left: 40px;
}
`
const LastRoadmapItemInfo = styled(RoadmapItemInfo)`
    justify-content: center;
`

const PercentageSellOut = styled.div`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    font-size: ${fontSize.titleMobile};
    font-weight: bolder;
    text-align: left;
    margin: 1rem 0;

    @media ${device.mobileM} {
        font-size: ${fontSize.titleMobile};
    }
    @media ${device.laptop} {
        font-size: ${fontSize.title};
    }
`

export const LastRoadmapItem = ({ image }: { image: string }) => {
    const item: IRoadmapItem = { color: Colors.bone, percentage: 0, text: 'Bummer. Major FOMO. Say what’s up on OS.' }
    return <RoadmapItemStyled>
        <LastLine />
        <LastRoadmapItemInfo {...item}>
            <Flex extras={`
                justify-content:center;
            `}>
                <LastTitleContainer>
                    <PercentageSellOut>SELL OUT</PercentageSellOut>
                    <ImageContainer>
                        <LastImage url={image} />
                    </ImageContainer>
                </LastTitleContainer>
            </Flex>
            <FlexCenter>{item.text}</FlexCenter>

        </LastRoadmapItemInfo>
    </RoadmapItemStyled>
}