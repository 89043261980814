import React from 'react'
import styled from 'styled-components';
import LogoPng from '../../../assets/bad_gifgs_logo.svg'
import { device } from '../../styles/breakpoints';
import { badGiftsURLs } from '../atoms/Utils'; 
import { LinksDiv } from '../atoms/Links';

const DivGlobal = styled.footer`
    width:100%;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
`

const LogoAnchor = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 100px 0 1rem 0;
    gap: 2.5rem;
    font-weight: 900;
    width:  100%;
`
const LogoImg = styled.img`
display:flex;
max-height:80px;
background-repeat: no-repeat;
background-size:contain;
background-position: center;

@media ${device.laptop} {
    margin-top: 70px;
    max-height:200px;
}
`
const Footer = () => {
    return (               
        <DivGlobal>
            <LogoAnchor>
                <a href={"#"}><LogoImg src={LogoPng}/></a>
            </LogoAnchor>
            <LinksDiv>
                <a href={badGiftsURLs.twitter} target={"_blank"}>TWITTER</a>
                <a href={badGiftsURLs.openSea} target={"_blank"}>OPENSEA</a>
            </LinksDiv>
        </DivGlobal>      
    )
}

export default Footer
