import styled from "styled-components"
import { Colors } from "../../styles/colors"
import React from 'react'
import { Logo } from "../atoms/Logo"
import { badGiftsURLs } from "../atoms/Utils"
import Hamburger from "../atoms/Hamburger";
import { useState } from 'react';
import ScarFace from '../../../assets/Group3.png'
import { device } from "../../styles/breakpoints"

const HamburgerGlobal = styled.div<{ hamburgerOpen: boolean }>`
            .navigation{
                width: 100%;
                height: 3em;
                display: flex;
                justify-content: space-between;
                border-bottom: 5px solid black;
                background-color: ${Colors.bone};
                padding: .5rem 0 .5rem 0rem;
                position:fixed;
                z-index:101
               
            }
            
            
            .navigation ul{
                font-family: 'RustonBasicBlack', Helvetica, sans-serif;
                display:flex;
                text-transform: uppercase;
                flex-wrap: wrap;
                height: 100%;
                justify-content: center;
                align-items: center;
                float: right;
                margin: 0 0 0 0;
                padding: 0px;
                gap: 3rem;
                margin-right: 1rem;
                overflow: hidden;
                
            }
            .navigation ul li{
                list-style-type: none;
                font-size: 1.5rem;

                a {cursor: pointer; 
                    text-decoration: none; color: #000; 
                    padding: 1rem 0 1rem 1rem;  margin: 0px;               
                }
                a:focus,  a:visited { color: inherit;text-decoration: none; color:#000; }
                a:hover { color: inherit; color:${Colors.red};  transition: color .1s ease; }   
            
            }
            .hamburger{
                display: none;
                z-index: 6;
            } 

            @media (max-width: 768px){

                .navigation{
                justify-content: space-between;
                }

                #logo {  order: 2; }
                #burger {  order: 1; }
            
                .hamburger{
                    display:flex;
                    justify-content: right;
                    padding-top: 10px;
                    margin-right: 0px;
                    z-index: 6;
                    padding: .5rem 2rem;
                }

                .navigation ul li{
                list-style-type: none;
                font-size: 2rem;

                

                a {cursor: pointer; 
                    text-decoration: none; color: #000; 
                    line-height: 0em;
                    display: inline-block;
                    width: 100%;
                    padding: 6rem 0; 
                                    
                }
                a:focus,  a:visited { color: inherit;text-decoration: none; color:#000; }
                a:hover, a:active  { color: inherit;text-decoration: none; color:${Colors.bone}; }
                
                    }
               
                .navigation ul{
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                    background-color: ${Colors.red};
                    text-align: center;
                    height: ${props => props.hamburgerOpen ? '100vh' : '0'};
                    min-width: 100%;
                    margin-top: 61px;
                    margin-right: 3rem;
                    position: absolute; 
                    transition: .5s;
                    z-index:10
                }
            }           
        `

        const ScarFaceImg = styled.div`
        width: 100%;
        min-height:200px;
        background-repeat: no-repeat;
        background-size:contain;
        background-position: center;
        background-image: url(${ScarFace});
        
        margin-top: 3.7rem;


        @media ${device.tablet} {
            display:none
        }
        `
export const Navbar = () => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const toggleHamburger = () => setHamburgerOpen(!hamburgerOpen)

    return (
        <HamburgerGlobal hamburgerOpen={hamburgerOpen}>
            <div className="navigation">
                <a  id="logo" href="#"><Logo /></a>
                <ul >
                    <li><a href={badGiftsURLs.twitter} target={"_blank"}>Twitter</a></li>
                    <li><a href={badGiftsURLs.openSea} target={"_blank"}>Opensea</a></li>
                    <li><ScarFaceImg/></li> 
                </ul>
                <div id="burger" className="hamburger" onClick={toggleHamburger}>
                    <Hamburger isOpen={hamburgerOpen}/>
                </div>
            </div>
        </HamburgerGlobal>
    )
} 