import { motion, AnimatePresence } from 'framer-motion';
import React from "react"
import styled from "styled-components"
import { device } from "../../styles/breakpoints"
import { fadeIn, staggerContainer } from '../atoms/variants';

export interface ISecretItem {
    image: string;
    title: string;
    text: string;
}
export interface SecretItemProps {
    me: ISecretItem;
    index: number;
    items: ISecretItem[];
}

const SecretContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 100%;
    width: 100%;
    
    p {
        margin: 0rem 0 1rem 0;
        font-size: 1.2rem;

    }
    
    @media ${device.tablet} {
        align-content: flex-start;
        
        p {
        margin: 1rem 0 0rem 0;
    }
    }
`
const Image = styled.div<{ url: string }>`
    background-image: url(${props => props.url});
    background-position: center;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;

    @media ${device.tablet} {}
`
const Title = styled.p`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
`

export const SecretPageItem = (props: SecretItemProps) => {
    
    return (

        // <motion.div variants={staggerContainer} initial="initial" animate='animate'>
        <SecretContainer >
            <Image url={props.me.image} />
            <Title>{props.me.title}</Title>
            <p>{props.me.text}</p>
        </SecretContainer>
        // </motion.div>

    )
}



