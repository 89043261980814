import styled from "styled-components"
import React from "react"
import { IRoadmapItem, LastRoadmapItem, RoadmapItem } from "../molecules/RoadmapItem"
import { Colors } from "../../styles/colors"
import { device } from "../../styles/breakpoints"
import RoadmapImg0 from '../../../assets/roadmap_0.png'
import RoadmapImg25 from '../../../assets/roadmap_25.png'
import RoadmapImg75 from '../../../assets/roadmap_75.png'
import RoadmapImgLast from '../../../assets/roadmap_last.png'
import DeathImg from '../../../assets/secondary_3.png'
import { fontSize } from "../../css/sizeTypographies"

const RoadmapStyled = styled.div`
    width: 100%;
    min-height: 300px;
    margin:0 0 5rem 0;
    padding: 0;
    display: block;
`
const Title = styled.span`
    font-family: 'RustonBasicBlack', Helvetica, sans-serif;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size:${fontSize.titleMobile};
    font-weight: bolder;
    padding: 1em;

    @media ${device.laptop} { 
        padding: 0 1em 1em 1em;
        font-size:${fontSize.title};
    }
`
const RoadmapsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media ${device.laptop} { 
        grid-template-columns: 1fr 1fr 1fr;
    }
`
const DeathImage = styled.div`
    height: 200px;
    width: 200px;
    background-size: contain;
    background-image:url(${DeathImg});
    position: relative;
    bottom: 0;
    right: 0;
    display: none;
    
    @media ${device.laptop} {
        display: block;
        margin: -8rem 0 0 1em;
    }
`

const Roadmaps: IRoadmapItem[] = [
    {
        color: Colors.green,
        percentage: 0,
        text: 'Y’know, we’re just happy to be here. We hope you can use Bad Gifts as a cheap way to onboard your frens and fam to web3. Let us know if they need some help!',
        image:RoadmapImg0
    },
    {
        color: Colors.blue,
        percentage: 1,
        text: 'We’ll start sending Bad Gifts around NFT Twitter. '
    },
    {
        color: Colors.green,
        percentage: 25,
        text: 'Bad Gifts will donate to our frens supporting safe drug use at GLIDE, Washington Heights CORNER Project.',
        image:RoadmapImg25
    },
    {
        color: Colors.yellow,
        percentage: 50,
        text: 'We love a Collab and we know some folks who do a real good stick n poke. '
    },
    {
        color: Colors.pink,
        percentage: 75,
        text: 'Hate to see you go but love to watch you walk away... Secondary sales may leave a little something in your wallet on the way out.',
        image:RoadmapImg75
    },
    {
        color: Colors.yellow,
        percentage: 100,
        text: 'Unexpected Gifts straight to some of y’all’s wallets - Could be in a brown paper bag. On fire.. but it’s what’s inside that counts. Re-Gift or Don’t!'
    }
]

export const Roadmap = () => {
    return <RoadmapStyled>
        <DeathImage />
        <Title>
            Sales Roadmap
        </Title>
        <RoadmapsContainer>
            {Roadmaps.map((val,index) => {
                return <RoadmapItem items={Roadmaps} index={index} me={val} key={index} />
            })}
        </RoadmapsContainer>
        <LastRoadmapItem image={RoadmapImgLast} />

    </RoadmapStyled>
}